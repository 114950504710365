import React from 'react';

const Section2 = () => {
    return (
        <div className="main-section-2">
           <div className="container-section-2">
               <img src="/assets/images/image-pilotage.png" alt="" className="background"/>
               <div className="title"><span>Pilotage et coordination</span> de projets digitaux complexes / plateforme e-commerce</div>
               <div className="paragraph">Au cours de mon expérience au sein du service Expérience Client chez Nexity, j’ai eu la chance de piloter un projet de plateforme de commande interne visant à centraliser les supports marketing.</div>
               <div className="button">En savoir plus</div>
           </div>
        </div>
    );
};

export default Section2;