import {useEffect, useState} from "react";

const TextRotateSection1 = () => {
    const words = ['Chef de projet', 'agile', 'créative', 'autonome', 'motivée', 'organisée'];
    const [activeIndex1, setActiveIndex1] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveIndex1((prevIndex) => (prevIndex + 1) % words.length);
        }, 1500);

        return () => clearInterval(intervalId);
    }, [words.length]);

    return (
        <div className="swap-container-section-1">
            {words.map((word, index) => (
                <div
                    key={index}
                    className={`word-section-1 ${index === activeIndex1 ? 'active' : ''}`}
                    style={{ display: index === activeIndex1 ? 'block' : 'none' }}
                >
                    {word}
                </div>
            ))}
        </div>
    );
};

export default TextRotateSection1;