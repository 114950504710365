import React from 'react';

const Section5 = () => {
    return (
        <div className="main-section-4">
            <img src="/assets/images/cv.png" alt=""/>
            <div className="right">
                <div className="title">Mon profil vous intéresse ?</div>
                <a href="/assets/cv.pdf" download="cv.pdf" className="button">Télécharger le CV</a>
            </div>
        </div>
    );
};

export default Section5;