import React from 'react';

const Contact = () => {
    return (
        <div className="main-contact">
            <div className="background">
                <img src="/assets/images/background-contact.png" alt="" className="background-img"/>
                <div className="background-cover"/>
            </div>
            <div className="container-contact">
                <div className="container-header">
                    <img src="/assets/images/photo-contact.png" alt="" className="left-part"/>
                    <div className="container-text-header">
                        <div className="title">Alexia SPIESSER-FERRETTE</div>
                        <div className="subtitle">Chef de projet digital marketing</div>
                        <div className="section-description">
                            <div className="title-description">Numéro de téléphone</div>
                            <div className="subtitle-description">(+33)06 10 79 68 63</div>
                        </div>
                        <div className="section-description">
                            <div className="title-description">Email</div>
                            <div className="subtitle-description">Alexiafte@gmail.com</div>
                        </div>
                        <div className="section-description">
                            <div className="title-description">Adresse</div>
                            <div className="subtitle-description">Paris 75015</div>
                        </div>
                    </div>
                </div>
                <div className="container-footer">
                    <a href={"https://www.linkedin.com/in/alexia-spiesser-ferrette/"} className="container-network">
                        <img src="/assets/logo/logo-link.svg" alt="logo" className="logo-network-footer" />
                    </a>
                    <a href="/assets/cv.pdf" download="cv.pdf" className="button">Télécharger le CV</a>
                </div>
            </div>
            <div className="br"/>
        </div>
    );
};

export default Contact;