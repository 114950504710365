import {useEffect, useState} from "react";

const TextRotateHeader = () => {
    const words = ['Chef de projet fonctionnel web / e-commerce', 'Chef de projet marketing', 'Développeur de partenariat / relation fournisseur', 'Chef de produit', 'Marketing opérationnel RSE'];
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 1500);

        return () => clearInterval(intervalId);
    }, [words.length]);

    return (
        <div className="swap-container">
            {words.map((word, index) => (
                <div
                    key={index}
                    className={`word ${index === activeIndex ? 'active' : ''}`}
                    style={{ display: index === activeIndex ? 'block' : 'none' }}
                >
                    {word}
                </div>
            ))}
        </div>
    );
};

export default TextRotateHeader;