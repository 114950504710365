import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate()
  const handleNavigate = (name) => {
    navigate(name)
  }
  return (
    <div className="main-not-found">
      <img src="/assets/images/404.jpg" alt="" />
      <div className="button" onClick={()=> handleNavigate('/')}>Go Home</div>
    </div>
  );
};

export default NotFound;