import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Redirect = ({link}) => {
  const history = useNavigate();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      history(link);
    }, 1000);
    return () => clearTimeout(redirectTimeout);
  }, [history]);

  return (
    <div>
      <p>Redirection en cours...</p>
    </div>
  );
};

export default Redirect;
