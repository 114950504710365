import React from 'react';

const Section5 = () => {
    return (
        <div className="main-section-5">
            <div className="title">Nous avons collaboré ensemble</div>
            <div className="container-section-5">
                <div className="section-collab expt">
                    <div className="img-ad">
                        <img src="/assets/images/collaborateur-1.png" alt="collaborateur 1" className="image"/>
                        <div className="background"/>
                    </div>
                    <div className="container-title">
                        <div className="content-text">
                            <div className="title">Ingrid VANUCCI</div>
                            <div className="subtitle">Animatrice Expérience Client</div>
                        </div>
                        <img src="/assets/logo/arrow-left.svg" alt="" className="arrow-left"/>
                    </div>
                    <div className="container-title-hover">
                        <div className="header-section">
                            <img src="/assets/logo/user-profile.jpg" alt="" className="perso-ref"/>
                            <div className="title-container">
                                <div className="title">Ingrid VANUCCI</div>
                                <div className="subtitle">Animatrice Expérience Client</div>
                            </div>
                        </div>
                        <div className="content">“J'ai travaillé avec Alexia pendant près de 3 ans. La recommander n'est pas juste une évidence, c'est aussi un crève coeur. Car je réalise que notre collaboration se termine... hors Alexia s'est rendue rapidement indispensable tant par ses qualités humaines que professionnelles. Au-delà de son savoir-faire et de sa créativité, elle est curieuse et à l'écoute, ce qui lui permet de s'adapter à chaque situation et d'être toujours à propos. Sa grande maturité et son savoir être au quotidien font d'elle une collaboratrice impliquée et investie qui prend chacune des missions qui lui sont confiées très à coeur.Alexia, tes futurs collègues et managers ont de la chance! Je ne doute pas de tes succès professionnels à venir!
                            +++ grande sensibilité produit & customer
                            +++ profil luxe
                            +++ organisation et gestion des outils
                            +++ communication
                            +++ suivi de projet”</div>
                    </div>
                </div>
                <div className="section-collab">
                    <div className="img-ad">
                        <img src="/assets/images/collaborateur-2.png" alt="collaborateur 1" className="image"/>
                        <div className="background"/>
                    </div>
                    <div className="container-title">
                        <div className="content-text">
                            <div className="title">Véronique POUYAU</div>
                            <div className="subtitle">CEO VDP</div>
                        </div>
                        <img src="/assets/logo/arrow-left.svg" alt="" className="arrow-left"/>
                    </div>
                    <div className="container-title-hover">
                        <div className="header-section">
                            <img src="/assets/logo/user-profile.jpg" alt="" className="perso-ref"/>
                            <div className="title-container">
                                <div className="title">Véronique POUYAU</div>
                                <div className="subtitle">CEO VDP</div>
                            </div>
                        </div>
                        <div className="content">“Alexia m’impressionne par sa capacité à jongler efficacement avec plusieurs projets, tout en gérant des plannings tendus, et des budgets serrés.Les compétences de Alexia sont sa rigueur, sa réactivité, son énergie et sa sensibilité produits.”</div>
                    </div>

                </div>
                <div className="section-collab">
                    <div className="img-ad">
                        <img src="/assets/images/collaborateur-3.png" alt="collaborateur 1" className="image"/>
                        <div className="background"/>
                    </div>
                    <div className="container-title">
                        <div className="content-text">
                            <div className="title">Christal JOLY</div>
                            <div className="subtitle">Animatrice Expérience Client</div>
                        </div>
                        <img src="/assets/logo/arrow-left.svg" alt="" className="arrow-left"/>
                    </div>
                    <div className="container-title-hover">
                        <div className="header-section">
                            <img src="/assets/logo/user-profile.jpg" alt="" className="perso-ref"/>
                            <div className="title-container">
                                <div className="title">Christal JOLY</div>
                                <div className="subtitle">Animatrice Expérience Client</div>
                            </div>
                        </div>
                        <div className="content">“Alexia travaille dans mon équipe depuis 3 ans sur la partie Marketing Expérience Client et fait preuve d’efficacité, de rigueur et de professionnalisme dans son travail qui sont très appréciables.Elle a su démontrer des qualités d’écoute et de mise en application immédiate et a progressé de façon régulière dans les différentes missions confiées.Collaboratrice motivée, engagée et agréable !Elle est aujourd’hui capable de mener en autonomie des projets complexes. J’ai éprouvé beaucoup de plaisir à collaborer ensemble.”</div>
                    </div>

                </div>
                <div className="section-collab">
                    <div className="img-ad">
                        <img src="/assets/images/collaborateur-4.png" alt="collaborateur 1" className="image"/>
                        <div className="background"/>
                    </div>
                    <div className="container-title">
                        <div className="content-text">
                            <div className="title">Alexandre DAUMAIN</div>
                            <div className="subtitle">Directeur Expérience Client</div>
                        </div>
                        <img src="/assets/logo/arrow-left.svg" alt="" className="arrow-left"/>
                    </div>
                    <div className="container-title-hover">
                        <div className="header-section">
                            <img src="/assets/logo/user-profile.jpg" alt="" className="perso-ref"/>
                            <div className="title-container">
                                <div className="title">Alexandre DAUMAIN</div>
                                <div className="subtitle">Directeur Expérience Client</div>
                            </div>
                        </div>
                        <div className="content">“Tes supports de communication sont toujours conçus avec une grande qualité, un sens du détail et une élégance remarquable. Ta capacité à travailler rapidement et à gérer efficacement ton temps est également impressionnante. Ton expertise et ton professionnalisme sont manifestes au quotidien. Travailler avec toi est un véritable bonheur !”</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section5;