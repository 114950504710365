import React from 'react';

const ButtonAbout = ({text,reverse}) => {
    return (
        <>
            {
                !reverse &&
                <div className="component-button-about">
                    {text}
                </div>
            }
            {
                reverse &&

                <div className="component-button-about reverse">
                    {text}
                </div>
            }
        </>
    );
};

export default ButtonAbout;