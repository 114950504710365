import React from "react";
import HeaderPage1 from "../components/home/header-page-1/HeaderPage1";
import Section1 from "../components/home/section-1/Section1";
import Section2 from "../components/home/section-2/Section2";
import Section3 from "../components/home/section-3/Section3";
import Section4 from "../components/home/section-4/Section4";
import Section5 from "../components/home/section-5/Section5";

const Home = () => {
    return (
        <div style={{overflowX:"hidden"}}>
           <HeaderPage1/>
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
        </div>
    );
};

export default Home;