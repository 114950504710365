
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./views/Home";

import TermAndPolicy from "./views/term-policy/TermAndPolicy";
import NotFound from "./views/notfound/NotFound";
import MainHeader from "./components/layout/header/main-header/MainHeader";
import MainFooter1 from "./components/layout/footer/main-footer-1/MainFooter1";
import React from "react";
import Contact from "./views/contact/Contact";
import About from "./views/about/About";
import Realisation from "./views/realisation/Realisation";
import Skills from "./views/skills/Skills";

const App = () => {


    return (
        <Router>
          <MainHeader/>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/realisations" element={<Realisation />} />
            <Route path="/competences" element={<Skills />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/condition" element={<TermAndPolicy  />} />
            <Route path="/policy" element={<TermAndPolicy />} />
          </Routes>
          <MainFooter1/>
        </Router>
  );
};

export default App;
