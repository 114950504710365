import React, {useState} from 'react';
import ButtonAbout from "../../components/about/button-about/ButtonAbout";

const About = () => {
    const [experienceFirst,setExperienceFirst] = useState(true)
    const [experienceSecond,setExperienceSecond] = useState(false)
    const [experienceThird,setExperienceThird] = useState(false)
    const [experienceFour,setExperienceFour] = useState(false)

    const handleChangeLogo = (number) => {
        if(number === 1){
            setExperienceFirst(true)
            setExperienceFour(false)
            setExperienceSecond(false)
            setExperienceThird(false)
        }else if(number === 2 ){
            setExperienceFirst(false)
            setExperienceFour(false)
            setExperienceSecond(true)
            setExperienceThird(false)
        }else if (number === 3 ){
            setExperienceFirst(false)
            setExperienceFour(false)
            setExperienceSecond(false)
            setExperienceThird(true)
        }else {
            setExperienceFirst(false)
            setExperienceFour(true)
            setExperienceSecond(false)
            setExperienceThird(false)
        }
    }
    return (
        <div className="main-about">
            <div className="background">
                <img src="/assets/images/background-about.png" alt="" className="background-img"/>
                <div className="background-cover"/>
            </div>
            <div className="container-header">
                <div className="title-header-about">A PROPOS DE MOI</div>
                <div className="container-button">
                    <a href="#div1" className="button-header">MES FORMATIONS</a>
                    <a href="#div2" className="button-header">MES EXPERIENCES</a>
                    <a href="#div3" className="button-header">MES PASSIONS</a>
                </div>
            </div>
            <div id="div1" className="container-section-1">
                <div className="logo-container">
                    <img src="/assets/logo/logo-1-about.png" alt="" className="logo"/>
                    <img src="/assets/logo/logo-2-about.png" alt="" className="logo"/>
                </div>
                <div className="title-explain">Master en Marketing Digital et International</div>
                <div className="paragraph">Ayant obtenu d'un Master en Marketing International et Digital, mes études m'ont offert une vision stratégique aiguisée pour élaborer des campagnes marketing percutantes à l'échelle mondiale. Cette expérience a été cruciale pour ma compréhension des tendances numériques, me permettant d'appliquer efficacement des tactiques innovantes dans un monde en constante évolution.</div>
                <div className="container-logo-1">
                    <ButtonAbout text={"Merchandising"} reverse={false}/>
                    <ButtonAbout text={"Stratégie de distribution"} reverse={false}/>
                    <ButtonAbout text={"Brand Strategy"} reverse={false}/>
                    <ButtonAbout text={"Category Management"} reverse={false}/>
                    <ButtonAbout text={"Product Management"} reverse={false}/>
                    <ButtonAbout text={"Agile Strategy"} reverse={false}/>
                </div>
            </div>
            <div id="div2" className="container-section-2">
                <div className="title-section-2">Mes expériences professionnelles</div>
                <div className="container-change-experiences">
                    <div className="container-header-experience">
                        <div className="img-experience" onClick={()=> handleChangeLogo(1)}>
                            <img src="/assets/logo/logo-nexisty.png" alt="nexisty" className="logo-experience"/>
                            {
                                !experienceFirst &&
                                <div className="background-experience"/>
                            }
                        </div>
                        <div className="img-experience" onClick={()=> handleChangeLogo(2)}>
                            <img src="/assets/logo/logo-nexisty.png" alt="nexisty" className="logo-experience"/>
                            {
                                !experienceSecond &&
                                <div className="background-experience"/>
                            }
                        </div>
                        <div className="img-experience" onClick={()=> handleChangeLogo(3)}>
                            <img src="/assets/logo/logo-nexisty.png" alt="nexisty" className="logo-experience"/>
                            {
                                !experienceThird &&
                                <div className="background-experience"/>
                            }
                        </div>
                        <div className="img-experience" onClick={()=> handleChangeLogo(4)}>
                            <img src="/assets/logo/logo-mickael.png" alt="michalak" className="logo-experience"/>
                            {
                                !experienceFour &&
                                <div className="background-experience"/>
                            }
                        </div>
                    </div>
                    <div className="container-paragraph">
                        {
                            experienceFirst &&
                            <>
                                <div className="title-first">Chef de Projet Marketing Expérience Client</div>
                                <div className="subtitle">Paris, 2022-2024</div>
                                <div className="paragraph">En tant que chef de projet digital marketing, j'ai orchestré la gestion opérationnelle d'une plateforme de commande et de centralisation interne. De la conception de la plateforme en étroite collaboration avec des développeurs externes au suivi des commandes et de la facturation, j’ai pris à cœur chacune de mes missions. Mon rôle incluait également la coordination des partenariats, la création de supports clients innovants, la gestion de la chaîne logistique, et une analyse approfondie des données pour optimiser le parcours client. Mon approche stratégique, combinée à une attention méticuleuse aux KPIs et à la gestion rigoureuse du budget, a été clé pour assurer le succès continu des projets que j’ai mené.</div>
                                <div className="container-section-2-skill">
                                    <ButtonAbout text={"Projet Digital"} reverse={true}/>
                                    <ButtonAbout text={"Management Produit "} reverse={true}/>
                                    <ButtonAbout text={"Méthode Agile"} reverse={true}/>
                                    <ButtonAbout text={"Relation Fournisseurs"} reverse={true}/>
                                    <ButtonAbout text={"Gestion de Budget"} reverse={true}/>
                                    <ButtonAbout text={"Relation Client"} reverse={true}/>
                                </div>
                            </>
                        }
                        {
                            experienceSecond &&
                            <>
                                <div className="title-first">Assistante Chef de Projet Marketing Expérience Client</div>
                                <div className="subtitle">Paris, 2020-2022</div>
                                <div className="paragraph">Cette prise de poste a été rapidement une belle opportunité de développer mon autonomie et mon agilité. Face à des problématiques inédites,  j’ai été nommé responsable des projets du service Expérience Client. Cet évènement m’a notamment offert une opportunité de mettre en exergue mes capacités à mener à bien des projets complexes en parfaite autonomie. Mes aptitudes de négociation ainsi que mon aisance relationnel et ma passion dévorante pour l’expérience client ont été des éléments clés dans la construction de projets solides.</div>
                                <div className="container-section-2-skill">
                                    <ButtonAbout text={"Projet Digital"} reverse={true}/>
                                    <ButtonAbout text={"Management Produit "} reverse={true}/>
                                    <ButtonAbout text={"Planification"} reverse={true}/>
                                    <ButtonAbout text={"Relation Fournisseurs"} reverse={true}/>
                                    <ButtonAbout text={"Gestion de Budget"} reverse={true}/>
                                    <ButtonAbout text={"Relation Client"} reverse={true}/>
                                </div>
                            </>
                        }
                        {
                            experienceThird &&
                            <>
                                <div className="title-first">Contrôleur comptable et business analyst</div>
                                <div className="subtitle">Paris, 2019</div>
                                <div className="paragraph">Mon parcours en tant que contrôleur comptable a constitué une solide base pour la réussite de projets futurs. Cette expérience a fourni les notions cruciales nécessaires à une gestion financière éclairée, notamment dans la tenue rigoureuse d'un budget. En comprenant en profondeur les mécanismes comptables et les implications financières, j'ai acquis une expertise qui sera un atout majeur pour la planification et la gestion budgétaire de mes projets entrepreneuriaux à venir.</div>
                                <div className="container-section-2-skill">
                                    <ButtonAbout text={"Risques financiers"} reverse={true}/>
                                    <ButtonAbout text={"Comptablité mandataire "} reverse={true}/>
                                    <ButtonAbout text={"Gestion de budget"} reverse={true}/>
                                    <ButtonAbout text={"Analyse financière"} reverse={true}/>
                                    <ButtonAbout text={"Gestion de Budget"} reverse={true}/>
                                </div>
                            </>
                        }
                        {
                            experienceFour &&
                            <>
                                <div className="title-first">Conseillère de vente</div>
                                <div className="subtitle">Paris, 2018</div>
                                <div className="paragraph">Ma première expérience professionnelle en tant que conseillère de vente au sein du prestigieux Printemps Haussmann a été une révélation déterminante. C'est ainsi que j'ai découvert ma passion profonde pour l'expérience client et la création de relations de confiance avec mes clients. Travailler dans ce cadre a été un véritable tremplin, stimulant ma volonté de contribuer à l'amélioration et à la valorisation de l'expérience client. Cette expérience m'a enseigné que placer le client au cœur de mes priorités est essentiel, et elle a façonné ma conviction inébranlable que la satisfaction client est la clé de toute entreprise prospère. Cet apprentissage précieux continue à guider mes actions et mes aspirations professionnelles.</div>
                                <div className="container-section-2-skill">
                                    <ButtonAbout text={"Relation Client"} reverse={true}/>
                                    <ButtonAbout text={"Gestion des stocks"} reverse={true}/>
                                    <ButtonAbout text={"Fidélisation"} reverse={true}/>
                                    <ButtonAbout text={"techniques de vente"} reverse={true}/>
                                </div>
                            </>
                        }
                    </div>
                    <div className="container-experiences">
                        <div className="container-experience">
                            <div className="img-experience" >
                                <img src="/assets/logo/logo-nexisty.png" alt="nexisty" className="logo-experience"/>
                                <div className="background-experience"/>
                            </div>
                            <div className="title-first">Chef de Projet Marketing Expérience Client</div>
                            <div className="subtitle">Paris, 2022-2024</div>
                            <div className="paragraph">En tant que chef de projet digital marketing, j'ai orchestré la gestion opérationnelle d'une plateforme de commande et de centralisation interne. De la conception de la plateforme en étroite collaboration avec des développeurs externes au suivi des commandes et de la facturation, j’ai pris à cœur chacune de mes missions. Mon rôle incluait également la coordination des partenariats, la création de supports clients innovants, la gestion de la chaîne logistique, et une analyse approfondie des données pour optimiser le parcours client. Mon approche stratégique, combinée à une attention méticuleuse aux KPIs et à la gestion rigoureuse du budget, a été clé pour assurer le succès continu des projets que j’ai mené.</div>
                        </div>
                        <div className="container-experience">
                            <div className="img-experience">
                                <img src="/assets/logo/logo-nexisty.png" alt="nexisty" className="logo-experience"/>
                                <div className="background-experience"/>
                            </div>                            <div className="title-first">Assistante Chef de Projet Marketing Expérience Client</div>
                            <div className="subtitle">Paris, 2020-2022</div>
                            <div className="paragraph">Cette prise de poste a été rapidement une belle opportunité de développer mon autonomie et mon agilité. Face à des problématiques inédites,  j’ai été nommé responsable des projets du service Expérience Client. Cet évènement m’a notamment offert une opportunité de mettre en exergue mes capacités à mener à bien des projets complexes en parfaite autonomie. Mes aptitudes de négociation ainsi que mon aisance relationnel et ma passion dévorante pour l’expérience client ont été des éléments clés dans la construction de projets solides.</div>

                        </div>
                        <div className="container-experience">
                            <div className="img-experience" >
                                <img src="/assets/logo/logo-nexisty.png" alt="nexisty" className="logo-experience"/>
                                <div className="background-experience"/>
                            </div>                            <div className="title-first">Contrôleur comptable et business analyst</div>
                            <div className="subtitle">Paris, 2019</div>
                            <div className="paragraph">Mon parcours en tant que contrôleur comptable a constitué une solide base pour la réussite de projets futurs. Cette expérience a fourni les notions cruciales nécessaires à une gestion financière éclairée, notamment dans la tenue rigoureuse d'un budget. En comprenant en profondeur les mécanismes comptables et les implications financières, j'ai acquis une expertise qui sera un atout majeur pour la planification et la gestion budgétaire de mes projets entrepreneuriaux à venir.</div>

                        </div>
                        <div className="container-experience">
                            <div className="img-experience">
                                <img src="/assets/logo/logo-mickael.png" alt="michalak" className="logo-experience"/>
                                <div className="background-experience"/>
                            </div>
                            <div className="title-first">Conseillère de vente</div>
                            <div className="subtitle">Paris, 2018</div>
                            <div className="paragraph">Ma première expérience professionnelle en tant que conseillère de vente au sein du prestigieux Printemps Haussmann a été une révélation déterminante. C'est ainsi que j'ai découvert ma passion profonde pour l'expérience client et la création de relations de confiance avec mes clients. Travailler dans ce cadre a été un véritable tremplin, stimulant ma volonté de contribuer à l'amélioration et à la valorisation de l'expérience client. Cette expérience m'a enseigné que placer le client au cœur de mes priorités est essentiel, et elle a façonné ma conviction inébranlable que la satisfaction client est la clé de toute entreprise prospère. Cet apprentissage précieux continue à guider mes actions et mes aspirations professionnelles.</div>
                        </div>
                    </div>
                </div>

            </div>
            <div id="div3" className="section-3">
                <div className="title-section">Mes passions </div>
                <div className="container-section">
                    <div className="title-section-back">Mes passions </div>
                    <div className="component-section-3">
                        <img src="/assets/images/passions-1.png" alt="" className="image-component"/>
                        <div className="container-text">
                            <div className="title-text-left">La Gastronomie</div>
                            <div className="br"/>
                            <div className="paragraph">En tant qu'épicurienne, ma passion pour l'expérience trouve sa source dans la quête délibérée du bonheur à travers la savoureuse exploration des plaisirs sensoriels.</div>
                        </div>
                    </div>
                    <div className="component-section-3">
                        <img src="/assets/images/passions-2.png" alt="" className="image-component"/>
                        <div className="container-text">
                            <div className="title-text-left">La Cosmétique</div>
                            <div className="br"/>
                            <div className="paragraph">Le raffinement, le sens du détail ainsi que toute l’expérience sensorielle qui en découle représente une véritable source d’inspiration quotidienne. J’admire particulièrement l’élégance qui s’en dégage.</div>
                        </div>
                    </div>
                    <div className="component-section-3">
                        <img src="/assets/images/passions-3.png" alt="" className="image-component"/>
                        <div className="container-text">
                            <div className="title-text-left">L’art de la table</div>
                            <div className="br"/>
                            <div className="paragraph">L’amour que je porte à l’art de la table réside dans le rafinnement et son soucis du détail où chaque élément est soigneusement orchestré pour créer une experience visuelle sophistiquée.</div>
                        </div>
                    </div>
                    <div className="component-section-3">
                        <img src="/assets/images/passions-4.png" alt="golf" className="image-component"/>
                        <div className="container-text">
                            <div className="title-text-left">Le Golf</div>
                            <div className="br"/>
                            <div className="paragraph">Ma passion pour le golf va au-délà d’un simple sport : C’est une quête exaltante qui marie précision, stratégie et élégance. </div>
                            <div className="container-exploit">
                                <img src="/assets/logo/logo-cup.png" alt="" className="img-exploit"/>
                                <div className="title-exploit">Championne départementale 2012</div>
                            </div>
                        </div>
                    </div>
                    <div className="component-section-3">
                        <img src="/assets/images/passions-5.png" alt="book" className="image-component"/>
                        <div className="container-text">
                            <div className="title-text-left">La lecture</div>
                            <div className="br"/>
                            <div className="paragraph">La lecture est selon moi une expérience tant intellectuelle qu’émotionnelle. Je trouve que c’est un moyen par lequel ma créativité peut s’épanouir sans contraintes et laisser libre cours à mon imagination.</div>
                        </div>
                    </div>
                    <div className="component-section-3">
                        <img src="/assets/images/passions-6.png" alt="" className="image-component"/>
                        <div className="container-text">
                            <div className="title-text-left">Les Loisirs créatifs</div>
                            <div className="br"/>
                            <div className="paragraph">Ma passion réside dans le plaisir libérateur que les activités créatives répresentent. Mon imagination s’apanouie à travers divers projets artistiques m’offrant ainsi une évasion enrichissante.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;