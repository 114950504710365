import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const MainFooter1 = () => {
  const { register, handleSubmit,resetField, watch } = useForm();
  const [message,setMessage] = useState("")
  const [openFailed,setOpenFailed] = useState(false)
  const [openCreated, setOpenCreated]  = useState(false)
  const [isLoader,setIsLoader] = useState(false)
  const navigate = useNavigate()
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailed(false)
  };

  const handleNavigate = (name) => {
    navigate(name)
  }

  const resetForm = () => {
    resetField('email');
  }



  function isEmail(val) {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(val);
  }

  const onSubmit = (data) => {
    if(!isEmail(watch('email'))){
      setOpenFailed(true)
      setMessage("Veuillez rentrer un email valide")
      setTimeout(()=> {
        setOpenFailed(false)
      },3000)
    }else{
      setIsLoader(true)
      setTimeout(()=> {
        setOpenCreated(true)
        setMessage("Send")
        setTimeout(()=> {
          resetForm()
          setOpenCreated(false)
          setIsLoader(false)
        },3000)
      },3000)
    }
  }
  return (
    <>
      <div className="main-footer-1">
        <div className="container-explain-remember">
          <div className="left-part-explain-remember">
            <img src="/assets/logo/logo-black.png" alt="logo" className="logo-part" />
              <div className="paragraph">Je suis déterminée à mettre ces compétences au service de l’excellence de l’expérience client, plaçant ainsi le client au cœur de mes priorités.</div>
              <div className="container-logo">
                <a href={"https://www.linkedin.com/in/alexia-spiesser-ferrette/"} className="container-network">
                  <img src="/assets/logo/logo-white-link.svg" alt="logo" className="logo-network-footer" />
                </a>
                <a href="/assets/cv.pdf" download="cv.pdf" className="button">Télécharger le CV</a>
              </div>
          </div>
          <div className="right-part-explain-remember">

            <div className="container-right-part">
              <div className="title-section">Liens essentiels</div>
              <div className="line"/>
              <div className="subtitle">Accueil</div>
              <div className="subtitle">Mes réalisations</div>
              <div className="subtitle">Mes compétences</div>
              <div className="subtitle">Mes passions</div>
            </div>

            <div className="container-right-part">
              <div className="title-section">Informations de contact</div>
              <div className="line"/>
              <div className="container-info">
                <img src="/assets/logo/phone-call.png" alt="logo" />
                <div className="content-info">
                  <div className="title-info">Phone</div>
                  <div className="subtitle">(+33)0610796863</div>
                </div>
              </div>
              <div className="container-info">
                <img src="/assets/logo/mail.png" alt="logo" />
                <div className="content-info">
                  <div className="title-info">Mail</div>
                  <div className="subtitle">Alexiafte@gmail.com</div>
                </div>
              </div>
              <div className="container-info">
                <img src="/assets/logo/pin.png" alt="logo" />
                <div className="content-info">
                  <div className="title-info">Adresse</div>
                  <div className="subtitle">Paris 750015</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-end-footer">
          <div className="title-left">© 2024 Alexia Spiesser Ferrette - All Rights Reserved.</div>
          <div className="title-right">
            <div className="title-click" onClick={() => handleNavigate('condition')}>Term & Conditions</div>
            <div className="line-vertical"/>
            <div className="title-click" onClick={()=> handleNavigate('policy')}>Privacy Policy</div>
          </div>
        </div>
      </div>
      {
        isLoader === true &&
        <div id="preloader">
          <div id="loader"></div>
        </div>
      }
      <Snackbar open={openCreated} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MainFooter1;