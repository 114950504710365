import { createRoot } from "react-dom/client";
import App from "./App";
import "./sass/App.scss";
import React from 'react'
import reportWebVitals from "./reportWebVitals";


const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );
}

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();