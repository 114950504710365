import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";

const BurgerMenu = () => {
  const navigate = useNavigate()

  const [isBurgerClicked, setBurgerClicked] = useState(false);
  const [isOverlayShown, setOverlayShown] = useState(false);
  const [isNavShown, setNavShown] = useState(false);
  const [isBodyOverflow, setBodyOverflow] = useState(false);
  const [isLightBackground, setIsLightBackground] = useState(true);

  useEffect(() => {
    const backgroundColor = getComputedStyle(document.body).backgroundColor;

    const brightness = calculateBrightness(backgroundColor);

    setIsLightBackground(brightness > 128);
  }, []);

  const calculateBrightness = (color) => {
    const rgb = color.match(/\d+/g);
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness;
  };

  const handleToggle = () => {
    setBurgerClicked(!isBurgerClicked);
    setOverlayShown(!isOverlayShown);
    setNavShown(!isNavShown);
    setBodyOverflow(!isBodyOverflow);
  };
  const navigateTo= (name) => {
    navigate(name)
    setBurgerClicked(!isBurgerClicked);
    setOverlayShown(!isOverlayShown);
    setNavShown(!isNavShown);
    setBodyOverflow(!isBodyOverflow);
  }
  return (
    <div>
      <div className={`burger ${isBurgerClicked ? 'clicked' : ''}`} onClick={handleToggle}>
        {
          isBurgerClicked  &&
          <img src="/assets/logo/close.png" alt="" className="logo-burger" />
        }
        {
          !isBurgerClicked &&
            <img src="/assets/logo/burger-white.png" alt="" className="logo-burger"/>

        }
      </div>

      <div className={`overlay ${isOverlayShown ? 'show' : ''}`} onClick={handleToggle}></div>

      <nav className={isNavShown ? 'show' : ''}>
        <ul className="main">
          <li><a onClick={() => navigateTo('/')}>Accueil</a></li>
          <li><a onClick={() => navigateTo('/about')}>A propos de moi</a></li>
          <li><a onClick={() => navigateTo('/competences')}>Compétences</a></li>
          <li><a onClick={() => navigateTo('/realisations')}>Réalisations</a></li>
          <li><a onClick={() => navigateTo('/contact')}>Contact</a></li>
        </ul>

        <div className="about">
          <p></p>
        </div>
      </nav>

      <div className="overlay"/>
    </div>
  );
};

export default BurgerMenu;