import React from 'react';
import SkillProgressBar from "../../components/skills/skill-progress-bar/SkillProgressBar";
import SquareSkillProduct from "../../components/skills/square-skill-product/SquareSkillProduct";
import SquareSkillEvent from "../../components/skills/square-skill-event/SquareSkillEvent";

const Skills = () => {
    return (
        <div className="main-skills">
            <div className="section-container-header">
                <div className="background">
                    <img src="/assets/images/competences.png" alt="" className="background-img"/>
                    <div className="background-cover"/>
                </div>
                <div className="container-header">
                    <div className="title-header-about">MES COMPETENCES</div>
                    <div className="container-button">
                        <a href="#div1" className="button-header">LES LOGICIELS</a>
                        <a href="#div2" className="button-header">Chef de projet digital</a>
                        <a href="#div3" className="button-header">Chef de produit</a>
                        <a href="#div4" className="button-header">GESTION D’EVENEMENTS</a>
                    </div>
                </div>
            </div>
            <div id="div1" className="section-1-skill">
                <div className="title-section-1">Mes compétences techniques</div>
                <div className="subtitle">Les logiciels que je maîtrise</div>
                <div className="container-logiciel">
                    <div className="container-progress">
                        <div className="title-progress-bar">Indesign</div>
                        <SkillProgressBar percent={90}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Monday</div>
                        <SkillProgressBar percent={90}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Illustrator</div>
                        <SkillProgressBar percent={50}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Notion</div>
                        <SkillProgressBar percent={80}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Photoshop</div>
                        <SkillProgressBar percent={50}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Figma</div>
                        <SkillProgressBar percent={60}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Sigeo</div>
                        <SkillProgressBar percent={60}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Trello</div>
                        <SkillProgressBar percent={90}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Suite Microsoft</div>
                        <SkillProgressBar percent={90}/>
                    </div>
                    <div className="container-progress">
                        <div className="title-progress-bar">Assana</div>
                        <SkillProgressBar percent={80}/>
                    </div>
                </div>
            </div>
            <div id="div2" className="section-2-skill">
                <div className="container-element-skill">
                    <img src="/assets/images/chief-project.png" alt="" className="left"/>
                    <div className="container-right">
                        <div className="title-right">Chef de Projet Digital </div>
                        <div className="subtitle">Mes compétences techniques et opérationnelles</div>
                        <div className="container-paragraph">
                            <div className="paragraph"><div className="point"/><span>Evènementiel</span> : Organiser des évènements pour valoriser les produits</div>
                            <div className="paragraph"><div className="point"/><span>Gestion de projet</span> Diriger le projet de refonte en orchestrant les efforts des équipes, des partenaires et des fournisseurs</div>
                            <div className="paragraph"><div className="point"/><span>Audit et stratégie</span> : Identifier les axes d’amélioration et élaborer une stratégie de refonte intégrale</div>
                            <div className="paragraph"><div className="point"/><span>Design et Expérience Utilisateur</span></div>
                            <div className="paragraph"><div className="point"/><span>Conversion et fidélisation</span> : Développer des stratégies pour améliorer le taux de conversion et renforcer la fidélité de la clientèle</div>
                            <div className="paragraph"><div className="point"/><span>Analyse et reporting</span>: Suivre les performances (KPI’s et ROI) et présenter des rapports détaillés avec des recommendations stratégiques</div>
                        </div>
                    </div>
                </div>
                <div className="container-kpi">
                    <div className="container-number">
                        <div className="number">+84%</div>
                        <div className="title-number">de chiffre d’affaire</div>
                    </div>

                    <div className="container-number">
                        <div className="number">+40K</div>
                        <div className="title-number">produits commandés*</div>
                    </div>

                    <div className="container-number">
                        <div className="number">+100</div>
                        <div className="title-number">références en gestion</div>
                    </div>
                </div>
            </div>
            <div id="div3" className="section-3-skill">
                <div className="left-part">
                    <div className="title-part">Chef de Produit</div>
                    <div className="subtitle-part">Rigoureuse, Sens du détail, Organisée</div>
                    <div className="paragraph-part"><div className="point"/><span>Analyse du marché</span> Réaliser des veilles concurrentielles et identifier les points d’entrés</div>
                    <div className="paragraph-part"><div className="point"/><span>Argumentaire commerciale</span>: rédiger des argumentation produit</div>
                    <div className="paragraph-part"><div className="point"/><span>Politique tarifaire :</span> Elaborer une stratégie dans une logique de prix/rentabilité</div>
                    <div className="paragraph-part"><div className="point"/><span>Négociation</span></div>
                    <div className="paragraph-part"><div className="point"/><span>Formation des opérationnelles :</span>Former les opérationnelles à la vente et la valorisation des produits</div>
                    <div className="paragraph-part"><div className="point"/><span>Analyse et reporting :</span> Suivre les performances (KPI’s et ROI) et présenter des rapports détaillés avec des recommendations stratégiques</div>
                    <div className="paragraph-part"><div className="point"/><span>Process de production : </span>Assurer le suivi de la chaîne de production/logistique en fonction des délais </div>
                </div>
                <div className="right-part">
                    <SquareSkillProduct title={"Kit Hôtelier"} subtitle={"BtoC à la livraison d’un logement"} img={"/assets/images/skill-product-1.png"}/>
                    <SquareSkillProduct title={"Kit Client Smile"} subtitle={"BtoB pour promouvoir un outil interne"} img={"/assets/images/skill-product-2.png"}/>
                    <SquareSkillProduct title={"Kit Prélivraison"} subtitle={"BtoC lors de la visite pré-livraison d’un logement"} img={"/assets/images/skill-product-3.png"}/>
                    <SquareSkillProduct title={"Kit Livraison"} subtitle={"BtoC pour la remise des clés aux clients"} img={"/assets/images/skill-product-4.png"}/>
                </div>
            </div>
            <div id="div4" className="section-4">
                <div className="left-part">
                    <div className="container-header-left">
                        <SquareSkillEvent title={"Dégustation PR"} subtitle={""} img={"/assets/images/skill-event.png"}/>
                        <img src="/assets/images/skill-event-1.png" alt="" className="img-left-header"/>
                    </div>
                    <img src="/assets/images/skill-event-2.png" alt="" className="body-img-left"/>
                    <img src="/assets/images/skill-event-3.png" alt="" className="body-img-left"/>
                </div>
                <div className="right-part">
                    <div className="title-part">Gestion d’évenements</div>
                    <div className="subtitle-part">Sens des priorités, Aisance relationnelle</div>
                    <div className="paragraph-part"><div className="point"/><span>Gestion budgétaire :</span>  Entretenir des relations de confiance et oser la négociation</div>
                    <div className="paragraph-part"><div className="point"/><span>Aisance et sens de la communication :</span> Diriger le projet de refonte en orchestrant les efforts des équipes, des partenaires et des fournisseurs</div>
                    <div className="paragraph-part"><div className="point"/><span>Polyvalence :</span> Pour piloter la logistique, être créatif, participer à la mise en place ...</div>
                    <div className="paragraph-part"><div className="point"/><span>Réactivité et rigueur </span></div>
                    <div className="paragraph-part"><div className="point"/><span>Planification :</span>Savoir prioriser les besoins et organiser un rétroplaning </div>
                    <div className="paragraph-part"><div className="point"/><span>Coordination :</span> Coordonner l’ensemble des moyens humains, matériels, techniques et logistiques nécessaires </div>
                </div>
            </div>
        </div>
    );
};

export default Skills;