import React from 'react';
import SwiperLoopReal from "../../components/real/swiper/SwiperLoopReal/SwiperLoopReal";

const Realisation = () => {
    return (
        <div className="main-realisation">
            <div className="background">
                <img src="/assets/images/real.png" alt="" className="background-img"/>
                <div className="background-cover"/>
            </div>
            <div className="container-header">
                <div className="title-header-about">MES REALISATIONS</div>
                <div className="container-button">
                    <a href="#div1" className="button-header">PRODUITS/GOODIES</a>
                    <a href="#div2" className="button-header">SUPPORTS ON/OFF</a>
                    <a href="#div3" className="button-header">ANIMATION PLATEFORME</a>
                </div>
            </div>
            <div id="div1" className="section-1">
                <div className="container-section-1">
                    <div className="title-section"><span>Création</span> de produits et de goodies</div>
                    <div className="container-element">
                        <div className="left-element">
                            <div className="paragraph">Dans le domaine de la création et du design de produits et goodies, ma démarche s'articule autour de la résolution des besoins spécifiques de la clientèle. Chaque produit est méticuleusement conçu pour répondre à des problématiques précises identifiées dans l'expérience utilisateur. Mon processus créatif, guidé par une observation attentive du marché et des retours clients, vise à élaborer des articles tant esthétiquement plaisants que fonctionnellement adaptés. En fixant des échéances précises, j'ai réussi à développer des solutions tangibles. Ces produits sont destinés à satisfaire non seulement les exigences des clients, mais également à faciliter les opérations internes, soulageant ainsi le quotidien opérationnel pour une expérience plus harmonieuse et efficace. Cet équilibre entre l'esthétique, la fonctionnalité et la facilité opérationnelle démontre mon engagement envers une approche holistique du design de produits.</div>
                            <img src="/assets/images/creat2-real.png" alt="" className="img-creat"/>
                        </div>
                        <img src="/assets/images/creation-real.png" alt="" className="right"/>
                    </div>
                </div>
            </div>
            <div id="div2" className="section-2">
                <div className="title-section-2"><span>Création</span> de supports ON et OFF</div>
                <div className="paragraph">Au fil de mon parcours, j'ai conçu des supports à la fois en ligne et hors ligne pour répondre de manière efficace à des problématiques spécifiques, tous élaborés dans l'optique de résoudre des points de friction identifiés dans le parcours client. Chaque création a été minutieusement pensée pour être une solution adaptée, s'inscrivant dans un cadre temporel défini, afin d'apporter des réponses concrètes et améliorer l'expérience globale du client. Cette approche proactive démontre mon engagement à identifier et à résoudre les obstacles potentiels, assurant ainsi une interaction fluide et satisfaisante pour ceux que je m'efforce de servir.</div>
                <div className="swiper-section-2">
                    <SwiperLoopReal/>
                </div>
            </div>
            <div id="div3" className="section-3">
                <div className="container-section-3">
                    <div className="title-section-3"><span>Animation</span> de plateforme de commande</div>
                    <div className="container-element-3">
                        <img src="/assets/images/animation-real.png" alt="" className="right"/>
                        <div className="left-element">
                            <div className="paragraph">Dans le domaine du développement et de la création de plateformes digitales, ma démarche est axée sur la résolution des besoins variés des utilisateurs. Les plateformes sont minutieusement conçues pour répondre à des problématiques spécifiques identifiées dans le parcours de l'utilisateur. Mon processus créatif, alimenté par une analyse approfondie des tendances technologiques et des retours des utilisateurs, vise à élaborer des solutions à la fois conviviales et technologiquement robustes. En fixant des échéances définies, j'ai réussi à mettre en place des plateformes digitales tangibles. Ces plateformes sont élaborées pour satisfaire non seulement les attentes des utilisateurs finaux mais également pour optimiser les opérations internes, apportant ainsi une valeur ajoutée tant au public externe qu'au fonctionnement interne de l'entreprise. Cet équilibre entre l'expérience utilisateur, la technologie de pointe et l'efficacité opérationnelle reflète mon engagement envers une approche complète du développement de plateformes digitales.</div>
                            <div className="mockup">
                                <img src="/assets/images/mock.png" alt="" className="img-creat"/>
                                <iframe width="510" height="338" className="iframe" src="https://www.youtube.com/embed/h4m_djy5-7w?si=looAHdtnx9e6TyMe" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Realisation;