import React, {useState} from 'react';


const SquareSkillProduct = ({title,subtitle,img}) => {
    const [clickedState, setClickedState] = useState(null)
    const handleClick = () => {
        setClickedState(!clickedState)
    }
    return (
        <div className="component-skill-product-square">
            {
                !clickedState &&
                <div className="container-skills-square" onClick={() => handleClick()}>
                    <img src="/assets/logo/plus-skill-product.png" alt="" className="img-skill" />
                    <div className="title-skill"> {title} </div>
                    <div className="subtitle">{subtitle}</div>
                </div>
            }
            {
                clickedState &&
                <div className="container-skills-square no-padding" onClick={() => handleClick()}>
                    <img src={img} className="img-cliked" alt=""/>
                </div>
            }
        </div>
    );
};

export default SquareSkillProduct;