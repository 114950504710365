import React from "react";
import TextRotateHeader from "../text-rotate-header/TextRotateHeader";

const HeaderPage1 = () => {
  return (
    <div className="main-header-page-1">
      <img src="/assets/images/background-header.png" className="background"/>
        <div className="container-global">
            <img src="/assets/logo/logo.png" alt="" className="logo" />
            <div className="first-text">ALEXIA SPIESSER-FERRETTE</div>
            <div className="subtitle"><TextRotateHeader/></div>
            <div className="container-speener">
                <img src="/assets/logo/speener.svg" className="speener" alt=""/>
                <img src="/assets/logo/arrow-down.png" className="arrow" alt=""/>
            </div>
        </div>
    </div>
  );
};

export default HeaderPage1;