import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "../drop-down-menu/DropdownMenu";
import BurgerMenu from "../burger-menu/BurgerMenu";



const MainHeader = () => {
  const navigate = useNavigate()
  const [y, setY] = useState(window.scrollY);

  const NameOfOptionServices = [
    { name: "Option 1", link: "https://www.google.com" },
    { name: "Option 2", link: "https://www.example.com" },
    { name: "Option 3", link: "https://www.example.org" },
    { name: "Option 4", link: "https://www.example.net" },
    { name: "Option 5", link: "https://www.example.edu" },
  ];

  const navigateTo= (name) => {
    navigate(name)
  }





  /*
    const handleNavigation = useCallback(
      e => {
        const window = e.currentTarget;
        let element = document.getElementById("myDIV");
        if (y < window.scrollY) {
          console.log("scrolling up");
          element.classList.add("white-main-header");
        } else {
          element.classList.remove("white-main-header");
        }
        setY(window.scrollY);
      }, [y]
    );

    useEffect(() => {
      setY(window.scrollY);
      window.addEventListener("scroll", handleNavigation);
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }, [handleNavigation]); */

  return (
    <div id="myDIV" className="main-header">
      <img src="/assets/logo/logo-header.png" alt="logo weworkstudio" className="logo" style={{cursor:"pointer"}} onClick={() => navigateTo('/')}/>
      <div className="link-container">
        <div className="link responsive" onClick={() => navigateTo('/')}>Accueil</div>
        <div className="link responsive" onClick={() => navigateTo('/about')}>A propos de moi</div>
        <div className="link exp responsive" onClick={() => navigateTo('/competences')}>Compétences</div>
        <div className="link exp responsive" onClick={() => navigateTo('/realisations')}>Réalisations</div>
        <div className="link exp responsive" onClick={() => navigateTo('/contact')}>Contact</div>
      </div>
      <div className="burger-menu">
        <BurgerMenu/>
      </div>
    </div>
  );
};

export default MainHeader;