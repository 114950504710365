import React from 'react';

const Section3 = () => {
    return (
        <div className="main-section-3">
            <img src="/assets/images/fleur.png" alt="" className="fleur-right"/>
            <div className="title-section-3"><span>Réalisation et création</span> de supports marketing divers </div>
            <div className="subtitle">Dans le cadre de mes missions en tant que Chef de projet marketing Expérience Client j’ai été amené à réaliser de nombreux supports BtoC et BtoB.</div>
            <div className="container-real">
                <div className="container-supp">
                    <img src="/assets/images/support-1.png" alt=""/>
                    <div className="text-supp">Développement de supports on/off</div>
                </div>
                <div className="container-supp">
                    <img src="/assets/images/support-2.png" alt=""/>
                    <div className="text-supp">Développement de produits</div>
                </div>
                <div className="container-supp">
                    <img src="/assets/images/support-3.png" alt=""/>
                    <div className="text-supp">Animation d’une plateforme e-commerce</div>
                </div>
            </div>
        </div>
    );
};

export default Section3;