import React from "react";
import TextRotateSection1 from "../text-rotate-section-1/TextRotateSection1";

const Section1 = () => {

    return (
    <div className="main-section-1">
          <div className="right-part reverse">
            <div className="title">A PROPOS DE MOI</div>
            <div className="container-subtitle">
                <div className="subtitle">Je suis </div>
                <TextRotateSection1 />
            </div>
            <div className="paragraph">Avec une solide expérience en gestion de projets digitaux, je m’engage à être votre alliée pour apporter une réelle plus-value à votre entreprise. Initiatives, rigueur et compétences de négociation sont mes atouts. Je me distingue par ma rigueur, mes capacités d’observation et d’anticipation qui garantissent l’efficacité de mes projets. Je suis déterminée à mettre ces compétences au service de l’excellence de l’expérience client, plaçant ainsi le client au cœur de mes priorités.</div>
            <div className="button">En savoir plus</div>
          </div>
          <div className="left-part">
              <img src="/assets/images/alexia-section-1.png" alt="alexia-spiesser-ferrette" className="me"/>
              <img src="/assets/images/fleur.png" alt="fleur noir" className="flower"/>
          </div>
    </div>
  );
};

export default Section1;