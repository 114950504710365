import React, {useEffect, useRef, useState} from 'react';

const SkillProgressBar = ({percent}) => {
    const [displayPercent, setDisplayPercent] = useState(0);
    const progressBarRef = useRef(null);

    useEffect(() => {
        const progressBar = progressBarRef.current;
        const progressWrap = progressBar.parentElement;
        const getPercent = parseInt(percent) / 100;
        const progressWrapWidth = progressWrap.clientWidth;
        const progressTotal = getPercent * progressWrapWidth;
        const animationLength = 2500;

        progressBar.style.transition = `left ${animationLength / 1000}s`;
        progressBar.style.left = `${progressTotal}px`;

        let startDisplayPercent = displayPercent;
        const interval = setInterval(() => {
            if (startDisplayPercent < percent) {
                setDisplayPercent(prev => prev + 1);
                startDisplayPercent++;
            } else {
                clearInterval(interval);
            }
        }, (animationLength / 5) / (percent - startDisplayPercent));

        setTimeout(() => {
            progressBar.style.transition = '';
        }, animationLength);

        return () => clearInterval(interval);
    }, [percent, displayPercent]);

    return (
        <div className="progress-wrap progress" data-progress-percent={percent}>
            <div ref={progressBarRef} className="progress-bar progress"><div className="number">{displayPercent}</div></div>

        </div>
    );
};

export default SkillProgressBar;