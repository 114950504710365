import React from "react";
import isEmpty from "../../utils/isempty.utils";
import Redirect from "../../components/utils/redirect/Redirect";

const TermAndPolicy = () => {
  const policy = [
    {
      title : "Lorem Ipsum",
      paragraph : [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
      ]
    },
    {
      title : "Lorem Ipsum",
      paragraph : [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
      ]
    },
    {
      title : "Lorem Ipsum",
      paragraph : [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
      ]
    },
    {
      title : "Lorem Ipsum",
      paragraph : [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
      ]
    },
    {
      title : "Lorem Ipsum",
      paragraph : [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
      ]
    },
    {
      title : "Lorem Ipsum",
      paragraph : [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae cupiditate dicta est fugit hic illum in iste magni maxime minus molestias, nisi perspiciatis qui repellat saepe soluta totam veniam.",
      ]
    }
  ]


  return (
    <div className="main-conditions">
      {
        !isEmpty(policy) &&
        policy.map((value,key) =>
          <div className="term-container">
            <div className="title-term" key={key}>{value.title}</div>
            {
              value.paragraph.map((valuep,key2) =>
                 <div className="subtitle-term" key={key2}>{valuep}</div>
              )
            }
          </div>
        )
      }
      {
        isEmpty(policy) &&
        <Redirect link={"/"}/>
      }
    </div>
  );
};

export default TermAndPolicy;